@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: theme("colors.dark.background");
}

.bg-dark-secondary-hover:hover {
  background-color: theme("colors.dark.secondary-lightened");
}

.bg-dark-secondary-lightened {
  background-color: theme("colors.dark.secondary-lightened");
}